/**
 * Formats a date string in milliseconds since epoch to a long date format (e.g., "January 1, 2023").
 * @param {number} milliseconds - The milliseconds since epoch to format.
 * @returns {string} The formatted date string.
 */
export function formatDateToLongDateString(milliseconds: number) {
  const date = new Date(milliseconds);
  const options = { year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions;
  return date.toLocaleDateString('en-US', options);
}
