import React from 'react';
import { Container, Box, Typography, Breadcrumbs, Link } from '@mui/material';
import MenuBar from '../components/MenuBar';
import withLicenseCheck from '../components/LicenseCheck';
import LicensesModel from '../models/LicensesModel';
import 'leaflet/dist/leaflet.css';
import PartitionedMap from '../components/PartitionedMap';

interface ChronaProps {
  license: LicensesModel;
}

const Chrona: React.FC<ChronaProps> = () => {
  return (
    <div className=" flex flex-col md:flex-row h-screen">
      <MenuBar />
      <div className="dashboard-container flex-1 p-4 bg-gray-100 transparent-background space-y-4 overflow-y-auto">
        <Container>
          <Breadcrumbs
            aria-label="breadcrumb"
            className="avenir-font"
            style={{ marginTop: '40px', marginBottom: '16px' }}
          >
            <Link underline="hover" color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">
              Chrona<sup>TM</sup>
            </Typography>
          </Breadcrumbs>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4} />

          <Box>
            <PartitionedMap />
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default withLicenseCheck(Chrona, 'CHRONA');
