import { feature } from 'topojson-client';
import { GeometryCollection } from 'topojson-specification';
import { GeoJSON } from 'geojson';
import { getSuraPrediction } from '../network/prediction';

const temperatureColors = [ '#0571b0', '#92c5de', '#f7f7f7', '#f4a582', '#ca0020' ];
const precipitationColors = [ '#a6611a', '#dfc27d', '#f5f5f5', '#80cdc1', '#018571' ];

const temperatureLegend = [ 'Cold', 'Cool', 'Normal', 'Warm', 'Hot' ];
const precipitationLegend = [ 'Very wet', 'Wet', 'Normal', 'Dry', 'Very dry' ];

export async function loadTopoJSON(url: string): Promise<GeoJSON> {
  try {
    const jsonStr = await getSuraPrediction();

    // Parse the TopoJSON data
    const topoJsonData = JSON.parse(jsonStr);

    // Convert TopoJSON to GeoJSON
    const objectName = Object.keys(topoJsonData.objects)[0];
    return feature(topoJsonData, topoJsonData.objects[objectName] as GeometryCollection) as GeoJSON;
  } catch (error) {
    console.error('Error loading TopoJSON:', error);
    throw error;
  }
}

export function getColor(d: number, variable: string): string {
  switch (variable) {
    case 't2m':
      return temperatureColors[d];
    case 'pr':
      return precipitationColors[d];
    default:
      return '#f0f0f0';
  }
}

export function style(value: number, variable: string): L.PathOptions {
  return {
    fillColor: getColor(value, variable),
    weight: 1,
    opacity: 1,
    color: 'grey',
    dashArray: '1',
    fillOpacity: 0.7,
  };
}

export function getColorScale(variable: string): string[] {
  switch (variable) {
    case 't2m':
      return temperatureColors;
    case 'pr':
      return precipitationColors;
    default:
      return [];
  }
}



export function getColorLegend(variable: string): string[] {
  switch (variable) {
    case 't2m':
      return temperatureLegend;
    case 'pr':
      return precipitationLegend;
    default:
      return [];
  }
}

export function getMapProps(): ({}) {
  return {
    center: [40.7128, -74.006],
    zoom: 6,
    zoomControl: true,
    minZoom: 2,
    maxZoom: 10,
  };
}
