import MobileLogoSection from '../components/MobileLogoSection';
import WelcomePageLeftSection from '../components/WelcomePageLeftSection';
import { useNavigate } from 'react-router-dom';

const UserSigninError: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-screen transparent-background">
      {/* Logo Section (Mobile) */}
      <MobileLogoSection />
      {/* Desktop Layout */}
      <div className="flex-1 md:flex">
        {/* Left Section (Company Details) */}
        <WelcomePageLeftSection />
        {/* Right Section (Login Component) */}
        <div className="flex-1 flex items-center justify-center p-8 ">
          <div className="max-w-md w-full flex flex-col items-center justify-center">
            <h2 className="text-xl mb-8 text-[#00476C] font-bold text-center">Want access to Planette?</h2>
            <div className="mb-4 w-[80%] text-[#00476C] text-center">
              An unexpected error occurred. Please try again or contact support at support@planette.ai.
            </div>

            <button
              className="w-[45%] bg-[#1F5014] mt-4 text-white avenir-font py-1 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              onClick={() => navigate('/login')}
            >
              Try Again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSigninError;
