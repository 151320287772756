import React, { createContext, useReducer, ReactNode, useMemo } from 'react';
import UserModel from '../models/UserModel';
import { AuthActions } from '../constants/actionTypes';

interface AuthState {
  user: UserModel | null;
}

const initialState: AuthState = {
  user: null,
};

export const AuthContext = createContext<{
  state: AuthState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const authReducer = (state: AuthState, action: any): AuthState => {
  switch (action.type) {
    case AuthActions.LOGIN:
      return {
        ...state,
        user: action.payload.user,
      };
    case AuthActions.LOGOUT:
      return {
        ...state,
        user: null,
      };
    case AuthActions.UPDATE:
      return {
        ...state,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
