import axios, { AxiosResponse } from 'axios';
import config from '../config';
import LicensesModel from '../models/LicensesModel';

export async function getOrgLicenses(): Promise<LicensesModel[]> {
  try {
    const response: AxiosResponse = await axios.get(`${config.SERVER_URL + config.BASE_PATH}/v1/orgs/licenses`);
    return response.data as LicensesModel[];
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
