import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { userPool } from '../auth';
import { setUserAnalyticsTags } from '../utils/UserAnalyticsUtils';

import * as Yup from 'yup';
import MobileLogoSection from '../components/MobileLogoSection';
import WelcomePageLeftSection from '../components/WelcomePageLeftSection';
import OverlaySpinner from '../components/OverlaySpinner';
import { AxiosError } from 'axios';

const PasswordReset: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function resetpassword(email: string) {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    user.forgotPassword({
      onSuccess: (result) => {
        console.log('Reset Successful!');
        navigate('/login/resetpassword/confirm');
      },
      onFailure: (error) => {
        console.error('Error in reset', error);
        navigate('/login/error');
      },
      inputVerificationCode: (data) => {
        console.log('Code sent to: ', data);
        navigate('/login/resetpassword/confirm');
      },
    });
  }

  const handleSubmit = async (email: string) => {
    setLoading(true);
    setUserAnalyticsTags(email);
    try {
      resetpassword(email);
    } catch (e) {
      console.log('Error resetting user password', e);
      if (e === undefined || e === null) navigate('/login/error');
      else if (e instanceof AxiosError) {
        navigate('/login/error', {
          state: {
            errorCode: e.response?.data.errorCode,
            errorMessage: e.response?.data.errorMessage,
          },
        });
      } else navigate('/login/error');
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Please enter the email'),
    }),
    onSubmit: (values) => {
      handleSubmit(values.email);
    },
  });

  return (
    <div className="flex flex-col h-screen transparent-background">
      {loading && <OverlaySpinner />}

      {/* Logo Section (Mobile) */}
      <MobileLogoSection />
      {/* Desktop Layout */}
      <div className="flex-1 md:flex">
        {/* Left Section (Company Details) */}
        <WelcomePageLeftSection />
        {/* Right Section (Login Component) */}
        <div
          className="flex-1 flex items-center justify-center p-8 "
          onSubmit={formik.handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(formik.values.email);
            }
          }}
        >
          <div className="max-w-md w-full flex flex-col items-center justify-center">
            <h2 className="text-xl mb-3 text-[#00476C] avenir-font font-bold text-center">Snap! It happens.</h2>
            <div className="text-l mb-4 text-[#00476C] avenir-font font-regular text-center">
              Fortunately, it is easy to reset your password
            </div>
            <div className="mb-4 w-[80%]">
              <input
                name="email"
                type="email"
                placeholder="Email Address"
                id="email"
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red text-xs">{formik.errors.email}</div>
              ) : null}
            </div>
            <button
              className="w-[45%] bg-[#1F5014] mt-4 text-white avenir-font py-1 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              onClick={() => handleSubmit(formik.values.email)}
            >
              Send Code
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
