import React from 'react';
import CircularSpinner from './CircularSpinner';

const OverlaySpinner: React.FC = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-white bg-opacity-70 z-50">
      <div>
        <CircularSpinner />
      </div>
    </div>
  );
};

export default OverlaySpinner;
