import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../auth';
import logo from '../assets/horizontalthick.png';
import logoSymbol from '../assets/logothick.svg';

interface MenuItem {
  label: string;
  href: string;
  to: string;
  submenu?: MenuItem[];
}

const menuItems: MenuItem[] = [
  { label: 'Home', href: '#', to: '/home' },
  {
    label: 'Products',
    href: '#',
    to: '/home',
    submenu: [
      { label: 'Umi^TM', href: '#', to: '/umi' },
      { label: 'Sura^TM', href: '#', to: '/sura' },
      { label: 'Joro^TM', href: '#', to: '/home' },
      { label: 'Chrona^TM', href: '#', to: '/home' },
    ],
  },
];

const MenuBar: React.FC = () => {
  const navigate = useNavigate();

  const onMenuItemClick = (item: MenuItem) => {
    navigate(item.to);
  };

  return (
    <>
      {/* Desktop Navbar */}
      <div className="md:w-1/6 bg-[#CBDDD1] flex flex-col p-4 hidden md:flex">
        <img src={logo} alt="Planette Logo" className="w-[80%] mb-8 min-w-32" />
        <div className="flex flex-col text-[#1F5014] font-bold avenir-font flex-grow space-y-2 text-lg">
          {menuItems.map((item) => (
            <div key={item.label} className="ml-2">
              <button
                type="button"
                onClick={() => onMenuItemClick(item)}
                className="hover:underline hover:text-[#0d3a10] transition duration-200 text-left"
              >
                {item.label.includes('^') ? (
                  <span>
                    {item.label.split('^')[0]}
                    <sup>{item.label.split('^')[1]}</sup>
                  </span>
                ) : (
                  item.label
                )}
              </button>
              {item.submenu && (
                <ul className="pl-4 mt-2">
                  {item.submenu.map((subitem) => (
                    <li key={subitem.label} className="ml-2">
                      <button
                        type="button"
                        onClick={() => navigate(subitem.to)}
                        className="hover:underline hover:text-[#0d3a10] transition duration-200 text-left"
                      >
                        {subitem.label.includes('^') ? (
                          <span>
                            {subitem.label.split('^')[0]}
                            <sup>{subitem.label.split('^')[1]}</sup>
                          </span>
                        ) : (
                          subitem.label
                        )}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          <button
            type="button"
            className="mt-auto mb-2 ml-2 text-[#1F5014] hover:underline hover:text-[#0d3a10] transition duration-200 avenir-font text-left"
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </div>

      {/* Mobile Navbar */}
      <div className="md:hidden bg-[#CBDDD1] flex justify-between p-4 w-screen">
        <img src={logoSymbol} alt="Planette Logo" className="h-8 w-8" />
        <div className="flex text-[#00476C] justify-start font-bold avenir-font text-lg">
          {menuItems.map((item) => (
            <div key={item.label} className="ml-4">
              <button
                type="button"
                onClick={() => onMenuItemClick(item)}
                className="hover:underline hover:text-[#003554] transition duration-200"
              >
                {item.label}
              </button>
              {item.submenu && (
                <ul className="pl-4 mt-2">
                  {item.submenu.map((subitem) => (
                    <li key={subitem.label}>
                      <button
                        type="button"
                        onClick={() => navigate(subitem.to)}
                        className="hover:underline hover:text-[#003554] transition duration-200"
                      >
                        {subitem.label}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
        <button
          type="button"
          className="ml-auto font-bold text-[#00476C] hover:underline hover:text-[#003554] transition duration-200"
          onClick={logout}
        >
          Logout
        </button>
      </div>
    </>
  );
};

export default MenuBar;
