import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, ErrorMessage } from 'formik';
import { Input } from '@mui/material';
import { validationSchema } from '../utils/PasswordUtils';

interface FormDialogProps {
  isOpen: boolean;
  handleSubmit: Function;
}

export default function PasswordConfirmDialog({ isOpen, handleSubmit }: FormDialogProps) {
  const [open, setOpen] = React.useState(isOpen);

  const handleClose = async () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          confirmPassword: '',
          newPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await handleSubmit(values.confirmPassword);
        }}
      >
        {(formik) => (
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Set new password</DialogTitle>
            <DialogContent>
              <div>
                <Input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="Enter your password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                />
                <ErrorMessage name="newPassword" component="div" />
              </div>
              <div>
                <Input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm your password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
                <ErrorMessage name="confirmPassword" component="div" />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={async () => {
                  await handleSubmit(formik.values.confirmPassword);
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </React.Fragment>
  );
}
