import axios, { AxiosResponse } from 'axios';
import config from '../config';
import UserModel from '../models/UserModel';
import { setUserAnalyticsTags } from '../utils/UserAnalyticsUtils';

export async function getCurrentUserSession(): Promise<UserModel> {
  try {
    const response: AxiosResponse = await axios.get(`${config.SERVER_URL + config.BASE_PATH}/v1/users/session/me`);
    const user = response.data as UserModel;
    setUserAnalyticsTags(user.email);
    return user;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function createUser(email: string): Promise<boolean> {
  try {
    const response: AxiosResponse = await axios.post(
      `${config.SERVER_URL + config.UNAUTH_PATH}/v1/users`,
      {
        email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.status >= 200 && response.status <= 299) return true;
    return false;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
