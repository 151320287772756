import { useEffect } from 'react';
import mixpanel from "mixpanel-browser";

const useUserAnalytics = () => {

  useEffect(() => {
    // Initialize Mixpanel
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        debug: true,
        track_pageview: "url-with-path",
        persistence: "localStorage",
      });
    }
    

    // Load Microsoft Clarity script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY_KEY}");
    `;
    document.head.appendChild(script);
  }, [process.env.REACT_APP_CLARITY_KEY]);
};

export function setUserAnalyticsTags(email: string, userId?: string) {
  mixpanel.identify(email);
  const person_attrs: any = {'$email': email};
  if (userId) {
    person_attrs['$user_id'] = userId;
  }
  mixpanel.people.set(person_attrs);

  window.clarity('set', 'user_email', email);
  window.clarity('identify', email);
}

export default useUserAnalytics;
