import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import markerRetina from 'leaflet/dist/images/marker-icon-2x.png';
import { toast } from 'react-toastify';
import MenuBar from '../components/MenuBar';
import { getHindcastPredictionByCoordinates, postHindcastPredictionFileUpload } from '../network';
import LicensesEnum from '../constants/enums/LicensesEnum';
import OverlaySpinner from '../components/OverlaySpinner';

// Fix for missing marker icons
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetinaUrl: markerRetina,
  shadowUrl: markerShadow,
});

// Move LocationMarker component outside of LocationPage
const LocationMarker: React.FC<{
  latitude: string;
  longitude: string;
  setLatitude: (lat: string) => void;
  setLongitude: (lng: string) => void;
}> = ({ latitude, longitude, setLatitude, setLongitude }) => {
  return latitude && longitude ? (
    <Marker position={[parseFloat(latitude), parseFloat(longitude)]}>
      <Popup>Selected location</Popup>
    </Marker>
  ) : null;
};

const LocationPage: React.FC = () => {
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [license, setLicense] = useState(LicensesEnum.CHRONA);
  const [inputMethod, setInputMethod] = useState<'coordinates' | 'file'>('coordinates');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const licenseParam = params.get('license') as LicensesEnum;
    if (licenseParam) {
      setLicense(licenseParam);
    }
  }, []);

  const handleLatitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLatitude(event.target.value);
    setFile(null);
  };

  const handleLongitudeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLongitude(event.target.value);
    setFile(null);
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
      setLatitude('');
      setLongitude('');
    }
  };

  const handleFileUpload = async (givenFile: File) => {
    if (license === LicensesEnum.CHRONA || license === LicensesEnum.SURA || license === LicensesEnum.JORO) {
      await postHindcastPredictionFileUpload(givenFile, license);
    }
  };

  const handleFetchByCoordinates = async (lat: number, long: number) => {
    if (license === LicensesEnum.CHRONA || license === LicensesEnum.SURA || license === LicensesEnum.JORO) {
      await getHindcastPredictionByCoordinates(lat, long, license);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (file !== null) {
        await handleFileUpload(file);
      } else {
        await handleFetchByCoordinates(parseFloat(latitude), parseFloat(longitude));
      }
      toast.success('The response has been successfully downloaded!');
    } catch (e) {
      toast.error('Something went wrong. Please try again');
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen">
      <MenuBar />
      <div className="flex flex-col transparent-background w-1/4 p-4 space-y-4">
        {loading && <OverlaySpinner />}
        <div className="flex flex-col items-start space-y-2">
          <div className="flex space-x-2">
            <button
              type="button"
              onClick={() => setInputMethod('coordinates')}
              style={{
                backgroundColor: inputMethod === 'coordinates' ? '#1F5014' : '#e0e0e0',
                color: inputMethod === 'coordinates' ? '#ffffff' : '#000000',
              }}
              className="p-2 border rounded w-36 avenir-font"
            >
              Select a location
            </button>
            <button
              type="button"
              onClick={() => setInputMethod('file')}
              style={{
                backgroundColor: inputMethod === 'file' ? '#1F5014' : '#e0e0e0',
                color: inputMethod === 'file' ? '#ffffff' : '#000000',
              }}
              className="p-2 border rounded w-36 avenir-font"
            >
              Drag & Drop
            </button>
          </div>
          {inputMethod === 'coordinates' && (
            <div className="flex flex-col space-y-2 w-full">
              <div>
                <label htmlFor="latitude" className="font-bold avenir-font">
                  Latitude:{' '}
                </label>
                <input
                  type="text"
                  id="latitude"
                  value={latitude}
                  onChange={handleLatitudeChange}
                  className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                  disabled={!!file}
                />
              </div>
              <div>
                <label htmlFor="longitude" className="font-bold avenir-font">
                  Longitude:{' '}
                </label>
                <input
                  type="text"
                  id="longitude"
                  value={longitude}
                  onChange={handleLongitudeChange}
                  className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                  disabled={!!file}
                />
              </div>
            </div>
          )}
          {inputMethod === 'file' && (
            <div className="w-full">
              <label htmlFor="file-upload" className="block font-bold avenir-font">
                Upload CSV File:
              </label>
              <div className="border border-gray-300 rounded-md p-2 mt-2 flex items-center">
                <input type="file" id="file-upload" accept=".csv" onChange={handleFileInputChange} className="hidden" />
                {file ? (
                  <div className="flex items-center justify-between w-full">
                    <span>{file.name}</span>
                    <button
                      type="button"
                      onClick={() => {
                        setFile(null);
                      }}
                      className="ml-2 text-red-500"
                    >
                      Clear
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor="file-upload"
                    className={`cursor-pointer block p-4 bg-gray-200 rounded-md w-full text-center avenir-font ${file ? 'bg-opacity-50' : ''}`}
                    style={{ minHeight: '5rem' }}
                  >
                    Drag & Drop or Click to Upload
                  </label>
                )}
              </div>
            </div>
          )}
          <button
            type="button"
            onClick={handleSubmit}
            className={`p-2 text-white avenir-font rounded-md w-full ${!latitude && !longitude && !file ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{
              backgroundColor: '#28a745',
              transition: 'background-color 0.3s',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#789672';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#1F5014';
            }}
            disabled={!file && !(latitude && longitude)}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="flex-1 relative">
        <MapContainer center={[37.8, -122.21]} zoom={10} style={{ height: '100%', width: '100%' }}>
          <TileLayer url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png" />
          <LocationMarker
            latitude={latitude}
            longitude={longitude}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
          />
        </MapContainer>
      </div>
    </div>
  );
};

export default LocationPage;
