import { CognitoUserPool, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { jwtDecode } from 'jwt-decode';
import mixpanel from 'mixpanel-browser';
export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USERPOOL_ID ?? '',
  ClientId: process.env.REACT_APP_APPCLIENT_ID ?? '',
  // endpoint: "https://signin.app.dev.planette.ai"
});

export function isJwtExpired(jwt: string) {
  const decodedJwt: { [key: string]: any } = jwtDecode(jwt);
  return hasExpired(decodedJwt.exp);
}

// Input is in epochsInSeconds
export function hasExpired(expiryTimestamp: number) {
  return expiryTimestamp * 1000 > Date.now();
}

export async function logout() {
  console.log('Logging out the user');
  const currentUser = await userPool.getCurrentUser();
  if (currentUser != null) {
    await currentUser.signOut();
    resetTokensSessionStorage();
  }
  mixpanel.reset();
  window.location.replace('/login');
}

export async function refreshSession(
  session: CognitoUserSession,
  currentUser: CognitoUser,
): Promise<CognitoUserSession> {
  const accessToken = session ? session.getAccessToken() : null;
  if (!accessToken || !hasExpired(accessToken.getExpiration())) {
    console.log(`session validity: ${session.isValid()}`);
    if (!session.isValid()) {
      const refreshToken = session.getRefreshToken();
      return new Promise<CognitoUserSession>((resolve, reject) => {
        currentUser.refreshSession(refreshToken, async (refreshErr: Error, refreshedSession) => {
          if (refreshErr) {
            console.error('Error in refreshing', refreshErr);
            await logout();
            reject(new Error('User is not authenticated'));
            return;
          }
          currentUser.setSignInUserSession(refreshedSession);
          resolve(refreshedSession);
        });
      });
    }
  }
  return Promise.resolve(session);
}

export async function getAccessToken(): Promise<string | null> {
  const cognitoUserSession: CognitoUserSession | null = await getCurrentSession();
  if (cognitoUserSession === null) {
    return null;
  }
  return cognitoUserSession.getAccessToken().getJwtToken();
}

export async function getCurrentSession(): Promise<CognitoUserSession | null> {
  const currentUser = await getCurrentUser();
  if (currentUser == null) return null;

  return new Promise<CognitoUserSession | null>((resolve, reject) => {
    currentUser.getSession(async (err: Error, session: CognitoUserSession | null) => {
      if (err) {
        console.log('Error in getting the session', err.message || JSON.stringify(err));
        reject(new Error(err.message || 'Error in getting the session'));
        return;
      }
      if (session == null) {
        resolve(session);
        return;
      }
      try {
        const refreshedSession = await refreshSession(session, currentUser);
        setTokensToSessionStorage(
          refreshedSession.getAccessToken().getJwtToken(),
          refreshedSession.getIdToken().getJwtToken(),
        );
        resolve(refreshedSession);
      } catch (e) {
        reject(new Error((e as Error).message || 'Error in getting the session'));
      }
    });
  });
}

export function setTokensToSessionStorage(accessToken: string, idToken: string) {
  sessionStorage.setItem('accessToken', accessToken);
  sessionStorage.setItem('idToken', idToken);
}

export function resetTokensSessionStorage() {
  sessionStorage.setItem('accessToken', '');
  sessionStorage.setItem('idToken', '');
}

export async function getCurrentUser(): Promise<CognitoUser | null> {
  return new Promise<CognitoUser | null>((resolve, reject) => {
    try {
      resolve(userPool.getCurrentUser());
    } catch (e) {
      console.error('Error in fetching the currently logged in user.', e);
      reject(e);
    }
  });
}
