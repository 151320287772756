import React, { useState } from 'react';
import { CognitoUser, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { setTokensToSessionStorage, userPool } from '../auth';
import PasswordConfirmDialog from '../components/PasswordConfirmDialog';
import OverlaySpinner from '../components/OverlaySpinner';
import { toast } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';
import { AuthActions } from '../constants/actionTypes';
import { getCurrentUserSession } from '../network';
import { setUserAnalyticsTags } from '../utils/UserAnalyticsUtils';
import MobileLogoSection from '../components/MobileLogoSection';
import WelcomePageLeftSection from '../components/WelcomePageLeftSection';

const options = {
  autoClose: 6000,
  hideProgressBar: false,
  pauseOnHover: true,
  progress: 0.2,
};

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [sessionUserAttributes, setSessionUserAttributes] = useState({});
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(
    new CognitoUser({
      Username: '',
      Pool: userPool,
    }),
  );

  const { dispatch } = useAuth();

  const handleSuccessfulLogin = async () => {
    const user = await getCurrentUserSession();
    dispatch({
      type: AuthActions.UPDATE,
      payload: {
        user: user,
      },
    });
    navigate('/home');
  };

  async function completeNewPasswordChallenge(user: CognitoUser, password: string): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      user.completeNewPasswordChallenge(password, sessionUserAttributes, {
        onSuccess: (result) => {
          user.getUserAttributes(function (err, result) {
            if (err) {
              console.log('err', err);
              reject(err);
              return;
            }
          });
          resolve(result);
        },
        onFailure: (err) => {
          console.log('login failed', err);
          reject(err);
        },
      });
    });
  }

  async function authenticateUser(
    user: CognitoUser,
    username: string,
    password: string,
  ): Promise<CognitoUserSession | null> {
    return new Promise<CognitoUserSession | null>((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      user.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          console.log('Authentication Successful!');
          setTokensToSessionStorage(result.getAccessToken().getJwtToken(), result.getIdToken().getJwtToken());
          resolve(result);
        },
        onFailure: (error) => {
          console.error('Error in authentication', error);
          reject(error);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          delete userAttributes.email;

          // store userAttributes on global variable
          setSessionUserAttributes(userAttributes);
          setNewPasswordRequired(true);
          resolve(null);
        },
      });
    });
  }

  const handleSubmit = async (values: { email: string; password: string }) => {
    setLoading(true);
    setUserAnalyticsTags(values.email);
    try {
      const user = new CognitoUser({
        Username: values.email,
        Pool: userPool,
      });
      setCognitoUser(user);
      const userSession = await authenticateUser(user, values.email, values.password);
      if (userSession != null) {
        await handleSuccessfulLogin();
      } else {
        setNewPasswordRequired(true);
      }
    } catch (e) {
      toast.error('Invalid email id or password!', options);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Please enter the email'),
      password: Yup.string().required('Please enter the password.'),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleNewPasswordSubmit = async (newPassword: string) => {
    const userSession = await completeNewPasswordChallenge(cognitoUser, newPassword);
    setNewPasswordRequired(false);
    await handleSuccessfulLogin();
  };

  return (
    <div className="flex flex-col h-screen transparent-background">
-     {loading && <OverlaySpinner />}
      {newPasswordRequired && (
        <PasswordConfirmDialog isOpen={newPasswordRequired} handleSubmit={handleNewPasswordSubmit} />
      )}
      {/* Logo Section (Mobile) */}
      <MobileLogoSection />
      {/* Desktop Layout */}
      <div className="flex-1 md:flex">
        {/* Left Section (Company Details) */}
        <WelcomePageLeftSection />
        {/* Right Section (Login Component) */}
        <div
          className="flex-1 flex items-center justify-center p-8 "
          onSubmit={formik.handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(formik.values);
            }
          }}
        >
          <div className="max-w-md w-full flex flex-col items-center justify-center">
            <h2 className="text-xl mb-8 text-[#00476C] font-bold text-center avenir-font">Welcome to Planette!</h2>
            <div className="mb-4 w-[80%]">
              <input
                name="email"
                type="email"
                placeholder="Email Address"
                id="email"
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red text-xs">{formik.errors.email}</div>
              ) : null}
            </div>
            <div className="mb-4 w-[80%]">
              <input
                name="password"
                type="password"
                id="password"
                placeholder="Password"
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={formik.handleChange}
                value={formik.values.password}
                onBlur={formik.handleBlur}
              />

              {formik.touched.password && formik.errors.password ? (
                <div className="text-red text-xs">{formik.errors.password}</div>
              ) : null}
            </div>
            <button
              className="w-[45%] bg-[#1F5014] mt-4 text-white avenir-font py-1 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              onClick={() => handleSubmit(formik.values)}
            >
              Login
            </button>
            <div className="text-l mt-8 text-[#00476C] text-center avenir-font">
              <a className="mr-4" href="/login/resetpassword">
                Forgot password?
              </a>
              |
              <a className="ml-4" href="/signup">
                No account yet? Create one here &gt;
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
