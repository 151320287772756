import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-side-by-side';
import './leaflet-sidebyside.css';
import 'leaflet-choropleth';
import { loadTopoJSON, style } from '../utils/ChloroplethMapUtils';
import OverlaySpinner from './OverlaySpinner';

declare global {
  interface Window {
    parseGeoraster: any;
  }
}

const LEFT_PANE = 'leftPane';
const RIGHT_PANE = 'rightPane';

// Extend Leaflet's control namespace
declare module 'leaflet' {
  function choropleth(data: any, options: any): L.Layer;
  namespace control {
    function sideBySide(leftLayer: L.Layer, rightLayer: L.Layer): L.Control;
  }
}

const PartitionedMap: React.FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const map = L.map('map', {
      center: [40.7128, -74.006],
      zoom: 6,
      zoomControl: true,
      minZoom: 2,
      maxZoom: 10,
    });

    // Create custom panes
    map.createPane(LEFT_PANE);
    map.createPane(RIGHT_PANE);

    // Ensure that the left pane is above the right pane
    map.getPane(LEFT_PANE)!.style.zIndex = '400';
    map.getPane(RIGHT_PANE)!.style.zIndex = '300';

    // Create base layers for each pane
    const leftLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors',
      pane: LEFT_PANE, // Assign to left pane
    }).addTo(map);

    const rightLayer = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors',
      pane: RIGHT_PANE, // Assign to right pane
    }).addTo(map);

    // Add side-by-side control
    L.control.sideBySide(leftLayer, rightLayer).addTo(map);

    // TODO: make an API call to get this file from backend
    // call ChloroplethMapUtils.loadTopoJSON()
    const urlToTopoJSON = '/combined_forecast.topojson.gz'; 

    const loadData = async (urlToTopoJSON: string, pane: string) => {
      try {
        const geoJsonData = await loadTopoJSON(urlToTopoJSON);

        const geoJsonLayer = L.geoJSON(geoJsonData, {
          style: (feature) => style(feature?.properties?.band_1_mean || 0, 't2m'),
          pane: pane,
        }).addTo(map);

        // Wait for the GeoJSON layer to finish loading
        geoJsonLayer.on('data:loaded', () => {
          // Now fit the bounds
          map.fitBounds(geoJsonLayer.getBounds(), { padding: [50, 50] });
          setLoading(false);
        });
      } catch (error) {
        console.error('Error loading GeoJSON:', error);
        setLoading(false);
      }
    };

    loadData(urlToTopoJSON, LEFT_PANE);

    return () => {
      map.remove();
    };
  }, []);

  return (
    <div id="map" style={{ position: 'relative', height: '500px', width: '100%' }}>
      {loading && <OverlaySpinner />}
    </div>
  );
};

export default PartitionedMap;
