import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { createUser } from '../network/user';
import * as Yup from 'yup';
import MobileLogoSection from '../components/MobileLogoSection';
import WelcomePageLeftSection from '../components/WelcomePageLeftSection';
import { AxiosError } from 'axios';

const SignupPage: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (email: string) => {
    setLoading(true);
    try {
      const success = await createUser(email);
      if (success) navigate('/signup/emailconfirm');
      else navigate('/signup/error');
    } catch (e) {
      console.log('Error creating user', e);
      if (e === undefined || e === null) navigate('/signup/error');
      else if (e instanceof AxiosError) {
        navigate('/signup/error', {
          state: {
            errorCode: e.response?.data.errorCode,
            errorMessage: e.response?.data.errorMessage,
          },
        });
      } else navigate('/signup/error');
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Please enter the email'),
    }),
    onSubmit: (values) => {
      handleSubmit(values.email);
    },
  });

  return (
    <div className="flex flex-col h-screen transparent-background">
      {/* Logo Section (Mobile) */}
      <MobileLogoSection />
      {/* Desktop Layout */}
      <div className="flex-1 md:flex">
        {/* Left Section (Company Details) */}
        <WelcomePageLeftSection />
        {/* Right Section (Login Component) */}
        <div
          className="flex-1 flex items-center justify-center p-8 "
          onSubmit={formik.handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(formik.values.email);
            }
          }}
        >
          <div className="max-w-md w-full flex flex-col items-center justify-center">
            <h2 className="text-xl mb-8 text-[#00476C] avenir-font font-bold text-center">Welcome to Planette!</h2>
            <div className="mb-4 w-[80%]">
              <input
                name="email"
                type="email"
                placeholder="Email Address"
                id="email"
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red text-xs">{formik.errors.email}</div>
              ) : null}
            </div>
            <button
              className="w-[45%] bg-[#1F5014] mt-4 text-white avenir-font py-1 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              onClick={() => handleSubmit(formik.values.email)}
            >
              Sign up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
