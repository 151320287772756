import React from 'react';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';

const NoAccess: React.FC = () => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const sendEmail = async (to: string[], subject: string, message: string) => {
    const data = { to, subject, message };

    try {
      const response = await fetch('https://qr2wg9frib.execute-api.us-east-2.amazonaws.com/prod/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.ok) {
        //alert(result.message);
      } else {
        console.error('Error:', result);
        alert('Failed to send email');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending email');
    }
  };

  const handleUpgrade = () => {
    const recipientEmail = ['kalai@planette.ai', 'info@planette.ai', 'sales@planette.ai'];
    const emailSubject = 'Someone wants to upgrade Umi!';
    const emailMessage = `User wants to renew Umi license. They clicked subscribe button from the no-access page after their free trial/subscription has ended. Please contact them at ${user?.email} .`;

    sendEmail(recipientEmail, emailSubject, emailMessage);
    navigate('/upgrade');
  };

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col items-center justify-center transparent-background h-screen">
      <h1 className="text-4xl mb-4">Uh, oh!</h1>
      <p className="mb-4">You do not have the necessary license to access this page.</p>
      <Box>
        <button
          onClick={handleUpgrade}
          style={{
            backgroundColor: '#1F5014',
            color: '#ffffff',
          }}
          className="p-2 border rounded w-36 avenir-font"
        >
          Subscribe Now
        </button>

        <button
          onClick={handleGoBack}
          style={{
            backgroundColor: '#1F5014',
            color: '#ffffff',
          }}
          className="p-2 border rounded w-36 avenir-font"
        >
          Home
        </button>
      </Box>
    </div>
  );
};

export default NoAccess;
