import React from 'react';
import { useNavigate } from 'react-router-dom';
import LicensesModel from '../models/LicensesModel';
import { formatDateToLongDateString } from '../utils/DateUtils';

interface LicenseCardProps {
  license: LicensesModel;
  isActive: boolean;
}

const LicenseCard: React.FC<LicenseCardProps> = ({ license, isActive }) => {
  const navigate = useNavigate();
  const { name, credits, totalCredits, expiry } = license;

  const descriptions: { [key: string]: string } = {
    UMI: "The El Nino and La Nina forecast with the world's best accuracy metrics.",
    SURA: 'Forecasts of key environmental variables at the county level, covering the globe.',
    JORO: 'Probabilistic forecasts of key environmental variables, on a 25-km global grid.',
    CHRONA: 'Backtests of the Planette Sura and Joro forecasting systems.',
  };

  const getDescription = (licenseName: string): string => {
    return descriptions[licenseName] || 'Description not available for this license.';
  };

  function handleOnClick() {
    if (isActive) {
      if (['UMI'].includes(name)) {
        navigate('/umi');
      } else if (['CHRONA'].includes(name)) {
        navigate('/chrona');
      } else if (['SURA'].includes(name)) {
        navigate('/sura');
      } else {
        navigate(`/location?license=${name}`);
      }
    }
  }

  return (
    <div
      className={`card shadow-md rounded-lg p-4 mb-4 ${isActive ? 'bg-[#CBDDD1] hover:shadow-lg cursor-pointer' : 'bg-gray-400 cursor-not-allowed'}`}
      onClick={handleOnClick}
      onKeyDown={(e) => e.key === 'Enter' && handleOnClick()}
      role="button"
      tabIndex={0}
      style={{ flex: '1 0 21%', margin: '5%', position: 'relative' }}
    >
      {!isActive && (
        <div className="coming-soon-label absolute top-0 right-0 bg-[#D1A1A1] text-black px-2 py-1 rounded-bl-lg">
          Coming Soon
        </div>
      )}

      <div className="card-header">
        <p className="font-bold text-xl avenir-font">
          {name
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')}
          <sup>TM</sup>
        </p>
      </div>
      <div className="card-body">
        {name.toLowerCase() !== 'umi' && name.toLowerCase() !== 'joro' && name.toLowerCase() !== 'sura' && name.toLowerCase() !== 'chrona'? (
          <>
            <div className="credit-bar">
              <div className="progress" style={{ width: isActive ? `${(credits / totalCredits) * 100}%` : '0%' }} />
            </div>
            <p className="mt-2 avenir-font">
              Remaining Credits: {credits} / {totalCredits}
            </p>
          </>
        ) : (
          <div style={{ height: '50px' }} />
        )}
        <p className="expiry-date mt-2 avenir-font">
          Expires on: {isActive ? formatDateToLongDateString(expiry) : '--'}
        </p>
      </div>

      <div className="card-description p-4 bg-white shadow-lg rounded-lg absolute top-full mt-2">
        <p className="avenir-font">{getDescription(name)}</p>
      </div>
    </div>
  );
};

export default LicenseCard;
