import axios from 'axios';
import { getAccessToken, isJwtExpired, logout } from './auth';

(function () {
  axios.interceptors.request.use(
    async (config) => {
      let accessToken = sessionStorage.getItem('accessToken');
      if (accessToken) {
        if (isJwtExpired(accessToken)) {
          accessToken = await getAccessToken();
        }
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error: Error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        await logout();
      }
      return Promise.reject(error);
    },
  );
})();
