import MobileLogoSection from '../components/MobileLogoSection';
import WelcomePageLeftSection from '../components/WelcomePageLeftSection';
import { useLocation, useNavigate } from 'react-router-dom';

const UserSignupError: React.FC = () => {
  const { state } = useLocation();
  const { errorCode, errorMessage } = state;

  // Determine the message based on the error code value
  let displayMessage = 'An unexpected error occurred. Please try again or contact support at support@planette.ai.';
  switch (errorCode) {
    case 'ORG_DOES_NOT_EXISTS':
      displayMessage =
        'Planette is not available for your organization yet. If you are interested in a trial, contact us at sales@planette.ai';
      break;
    case 'EMAIL_IS_INVALID':
      displayMessage =
        'The email you entered is invalid. Please try again. If the problem persists, contact us at support@planette.ai';
      break;
    case 'MAX_USERS_FOR_ORG_REACHED':
      displayMessage =
        'Your organization has reached the maximum of allowed user accounts. Please contact support@planette.ai to request an increase';
      break;
    case 'USER_ALREADY_EXISTS':
      displayMessage =
        'An account with this email already exists. Please log in or contact support@planette.ai for assistance.';
      break;
    case 'SWW':
      displayMessage = 'You hit a glitch in our system. If the problem persists, contact us at support@planette.ai.';
      break;
    default:
      displayMessage = 'An unexpected error occurred. Please try again or contact support at support@planette.ai.';
      break;
  }

  return (
    <div className="flex flex-col transparent-background h-screen">
      {/* Logo Section (Mobile) */}
      <MobileLogoSection />
      {/* Desktop Layout */}
      <div className="flex-1 md:flex">
        {/* Left Section (Company Details) */}
        <WelcomePageLeftSection />
        {/* Right Section (Login Component) */}
        <div className="flex-1 flex items-center justify-center p-8 ">
          <div className="max-w-md w-full flex flex-col items-center justify-center">
            <h2 className="text-xl mb-8 text-[#00476C] font-bold text-center">Want access to Planette?</h2>
            <div className="mb-4 w-[80%] text-[#00476C] text-center">{displayMessage}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSignupError;
