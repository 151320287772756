const config = {
  SERVER_URL: 'http://localhost:8080',
  BASE_PATH: '/client',
  UNAUTH_PATH: '/unauth',
  PREDICTION_PATH: '/v1/prediction',
};

if (process.env.REACT_APP_ENV === 'dev') {
  config.SERVER_URL = 'https://backend.dev.planette.ai';
}

if (process.env.REACT_APP_ENV === 'prod') {
  config.SERVER_URL = 'https://backend.planette.ai';
}

export default config;
