import React, { useContext, useEffect, useState } from 'react';
import MenuBar from '../components/MenuBar';
import { AuthContext } from '../contexts/AuthContext';
import { getOrgLicenses } from '../network/org';
import LicensesModel from '../models/LicensesModel';
import CircularSpinner from '../components/CircularSpinner';
import LicensesEnum from '../constants/enums/LicensesEnum';
import LicenseGrid from '../components/Licensegrids';

const allLicenses = [LicensesEnum.UMI, LicensesEnum.SURA, LicensesEnum.JORO, LicensesEnum.CHRONA];

const HomePage: React.FC = () => {
  const {
    state: { user },
  } = useContext(AuthContext);
  const [licenses, setLicenses] = useState<LicensesModel[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getLicenses() {
      try {
        const orgLicenses = await getOrgLicenses();
        setLicenses(orgLicenses);
      } finally {
        setLoading(false);
      }
    }
    getLicenses();
  }, []);

  function handleContactSupport() {
    window.location.href = 'https://www.planette.ai/contact-support';
  }

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <MenuBar />
      <div className="flex-1 flex flex-col transparent-background p-4 h-full">
        <div className="flex justify-between items-center mb-4">
          <h4 className="avenir-font title" style={{ color: '#1F5014', marginBottom: '16px' }}>
            Welcome to the Planette Web Application!
          </h4>
          <button
            type="button"
            onClick={handleContactSupport}
            style={{
              backgroundColor: '#1F5014',
              color: '#ffffff',
            }}
            className="p-2 border rounded w-36 avenir-font"
          >
            Contact Support
          </button>
        </div>
        <h4 className="avenir-font" style={{ color: '#1F5014', fontSize: 22, marginBottom: '16px' }}>
          <span>{user?.email}</span>, here are your current subscriptions:
        </h4>

        {loading ? (
          <div className="flex flex-1 justify-center items-center">
            <CircularSpinner />
          </div>
        ) : (
          <LicenseGrid
            licenses={allLicenses.map((licenseName) => {
              const userLicense = licenses.find((license) => license.name === licenseName);
              const defaultLicense: LicensesModel = {
                licenseId: 'N/A',
                name: licenseName,
                orgId: 'N/A',
                credits: 0,
                totalCredits: 0,
                expiry: 0,
                enabled: false,
                events: [],
                createdAt: 0,
              };
              return userLicense || defaultLicense;
            })}
          />
        )}
      </div>
    </div>
  );
};

export default HomePage;
