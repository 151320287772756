import MobileLogoSection from '../components/MobileLogoSection';
import WelcomePageLeftSection from '../components/WelcomePageLeftSection';

const EmailConfirmationPage: React.FC = () => {
  return (
    <div className="flex flex-col transparent-background h-screen">
      {/* Logo Section (Mobile) */}
      <MobileLogoSection />
      {/* Desktop Layout */}
      <div className="flex-1 md:flex">
        {/* Left Section (Company Details) */}
        <WelcomePageLeftSection />
        {/* Right Section (Login Component) */}
        <div className="flex-1 flex items-center justify-center p-8 ">
          <div className="max-w-md w-full flex flex-col items-center justify-center">
            <h2 className="text-xl mb-8 text-[#00476C] font-bold text-center">Thank you for signing up!</h2>
            <div className="mb-4 w-[80%] text-[#00476C] text-center">
              Please see check your email inbox for an email from support@planette.ai
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmationPage;
