import logo from '../assets/horizontalthick.png';

const MobileLogoSection: React.FC = () => {
  return (
    <div className="bg-[#CBDDD1] p-8 text-white text-center md:hidden">
      <img src={logo} alt="Planette Logo" className="h-12 mx-auto mb-4" />
    </div>
  );
};

export default MobileLogoSection;
