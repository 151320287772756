import logo from '../assets/horizontalthick.png';

const WelcomePageLeftSection: React.FC = () => {
  return (
    <div className="flex-1 bg-[#CBDDD1] p-8 hidden md:flex flex-col items-center justify-center">
      <div className="w-[60%]">
        <img src={logo} alt="Planette Logo" className="w-full mb-4" />
        <p className="text-[#00476C] font-bold text-3xl avenir-font whitespace-nowrap">
          Ready to Look Into the Future?
        </p>
        <p className="text-md font-bold mt-2" />
      </div>
    </div>
  );
};

export default WelcomePageLeftSection;
