import React from 'react';
import LicensesModel from '../models/LicensesModel';
import LicenseCard from './LicenseCard';

const isLicenseActive = (license: LicensesModel): boolean => {
  return license.enabled;
};

interface LicenseGridProps {
  licenses: LicensesModel[];
}

const LicenseGrid: React.FC<LicenseGridProps> = ({ licenses }) => {
  return (
    <div className="license-grid">
      {licenses.map((license, index) => (
        <LicenseCard key={index} license={license} isActive={isLicenseActive(license)} />
      ))}
    </div>
  );
};

export default LicenseGrid;
