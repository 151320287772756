import React, { useState } from 'react';

import { AxiosError } from 'axios';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { userPool } from '../auth';
import { setUserAnalyticsTags } from '../utils/UserAnalyticsUtils';
import { validationSchema } from '../utils/PasswordUtils';
import MobileLogoSection from '../components/MobileLogoSection';
import WelcomePageLeftSection from '../components/WelcomePageLeftSection';
import OverlaySpinner from '../components/OverlaySpinner';

const PasswordResetConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  async function resetpasswordconfirm(values: {
    code: string;
    email: string;
    newPassword: string;
    confirmPassword: string;
  }): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const user = new CognitoUser({
        Username: values.email,
        Pool: userPool,
      });

      user.confirmPassword(values.code, values.confirmPassword, {
        onSuccess: (result) => {
          console.log('Reset Successful!');
          navigate('/login');
        },
        onFailure: (error) => {
          console.error('Error in authentication', error);
          navigate('/login/error');
        },
      });
    });
  }

  const handleSubmit = async (values: {
    code: string;
    email: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    setLoading(true);
    setUserAnalyticsTags(values.email);
    try {
      await resetpasswordconfirm(values);
    } catch (e) {
      console.log('Error resetting user password', e);
      if (e === undefined || e === null) navigate('/login/error');
      else if (e instanceof AxiosError) {
        navigate('/login/error', {
          state: {
            errorCode: e.response?.data.errorCode,
            errorMessage: e.response?.data.errorMessage,
          },
        });
      } else navigate('/login/error');
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      code: '',
      email: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <div className="flex flex-col h-screen transparent-background">
      {loading && <OverlaySpinner />}
      {/* Logo Section (Mobile) */}
      <MobileLogoSection />
      {/* Desktop Layout */}
      <div className="flex-1 md:flex">
        {/* Left Section (Company Details) */}
        <WelcomePageLeftSection />
        {/* Right Section (Login Component) */}
        <div
          className="flex-1 flex items-center justify-center p-8 "
          onSubmit={formik.handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit(formik.values);
            }
          }}
        >
          <div className="max-w-md w-full flex flex-col items-center justify-center">
            <h2 className="text-xl mb-3 text-[#00476C] avenir-font font-bold text-center">Reset Password</h2>
            <div className="text-l mb-4 text-[#00476C] avenir-font font-regular text-center">
              Please enter the code you received in the email and choose a new password
            </div>
            <div className="mb-4 w-[80%]">
              <input
                name="code"
                type="text"
                placeholder="Code"
                id="code"
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={formik.handleChange}
                value={formik.values.code}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red text-xs">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="mb-4 w-[80%]">
              <input
                name="email"
                type="email"
                placeholder="Email Address"
                id="email"
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red text-xs">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="mb-4 w-[80%]">
              <input
                type="password"
                id="newPassword"
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                name="newPassword"
                placeholder="Enter your new password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
              />
            </div>

            <div className="mb-4 w-[80%]">
              <input
                type="password"
                id="confirmPassword"
                className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                name="confirmPassword"
                placeholder="Confirm your password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
            </div>

            <button
              className="w-[45%] bg-[#1F5014] mt-4 text-white avenir-font py-1 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              onClick={() => handleSubmit(formik.values)}
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordResetConfirmation;
