import MobileLogoSection from '../components/MobileLogoSection';
import WelcomePageLeftSection from '../components/WelcomePageLeftSection';
import { useNavigate } from 'react-router-dom';

const UpgradeRequest: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col transparent-background h-screen">
      {/* Logo Section (Mobile) */}
      <MobileLogoSection />
      {/* Desktop Layout */}
      <div className="flex-1 md:flex">
        {/* Left Section (Company Details) */}
        <WelcomePageLeftSection />
        {/* Right Section (Login Component) */}
        <div className="flex-1 flex items-center justify-center p-8 ">
          <div className="max-w-md w-full flex flex-col items-center justify-center">
            <h2 className="text-xl mb-8 text-[#00476C] font-bold text-center">
              Thank you for your subscription request!
            </h2>
            <div className="mb-4 w-[80%] text-[#00476C] text-center">
              Someone from our sales team will be in touch with you shortly.
            </div>
            <button
              onClick={handleGoBack}
              style={{
                backgroundColor: '#1F5014',
                color: '#ffffff',
              }}
              className="p-2 border rounded w-36 avenir-font"
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeRequest;
