enum FileEnum {
  FORECAST = 'forecast_latest',
  ENSO_ALL_DATA = 'enso_data',
  NINO_34_FORECAST = 'forecast_nino_latest',
  NINO_TRUE = 'ninotrue',
  PRECIP_3M = 'precip_3m',
  PRECIP_6M = 'precip_6m',
  TEMP_3M = 'temp_3m',
  TEMP_6M = 'temp_6m',
  SURA_FORECAST = 'sura_forecast'
}

export default FileEnum;
