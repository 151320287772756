import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import LicensesModel from '../models/LicensesModel';
import { Typography, FormControl, Button, Select, MenuItem } from '@mui/material';
import { getPredictionDates } from '../network/prediction';

const mapBoxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

if (!mapBoxToken) {
  throw new Error("Mapbox access token is not defined in the environment variables");
}

mapboxgl.accessToken = mapBoxToken;

const TEMPERATURE_STYLE_ID = process.env.REACT_APP_TEMPERATURE_STYLE_ID;
const PRECIPITATION_STYLE_ID = process.env.REACT_APP_PRECIPITATION_STYLE_ID;
const HDD_STYLE_ID = process.env.REACT_APP_HDD_STYLE_ID;
const CDD_STYLE_ID = process.env.REACT_APP_CDD_STYLE_ID;
const USERNAME = process.env.REACT_APP_MAPBOX_USERNAME;

interface SuraProps {
    license: LicensesModel;
  }
  
  interface Layer {
    id: string;
    name: string;
  }

  const LayerControl: React.FC<{
    layers: Layer[];
    activeLayer: string | null;
    onLayerChange: (id: string) => void;
}> = ({ layers, activeLayer, onLayerChange }) => {
    React.useEffect(() => {
        if (layers.length > 0 && !activeLayer) {
            onLayerChange(layers[layers.length - 1].id);
        }
    }, [layers, activeLayer, onLayerChange]);

    return (
        <FormControl sx={{ flexGrow: 1 }}>
            <Select
                value={activeLayer || ''}
                onChange={(e) => onLayerChange(e.target.value)}
                size="small"
                sx={{ 
                    fontFamily: 'Avenir, sans-serif',
                    height: '35px',
                    '.MuiSelect-select': {
                        padding: '5px 14px'
                    }
                }}
            >
                {layers.map((layer) => (
                    <MenuItem 
                        key={layer.id} 
                        value={layer.id}
                        sx={{ 
                            fontFamily: 'Avenir, sans-serif',
                            padding: '4px 14px'
                        }}
                    >
                        {layer.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};


const FullscreenSura: React.FC = () => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const navigate = useNavigate();
  const popup = useRef<mapboxgl.Popup | null>(null);
  const [layers, setLayers] = useState<Layer[]>([]);
  const [activeLayer, setActiveLayer] = useState<string | null>(null);
  const [layerNameMapping, setLayerNameMapping] = useState<{ [key: string]: string }>({});
  const [updateDate, setUpdateDate] = useState<string>('');
  const [tabValue, setTabValue] = useState(0);
  const tabValueRef = useRef(tabValue);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false); 

  useEffect(() => {
    tabValueRef.current = tabValue;
  }, [tabValue]);




  const Legend: React.FC<{ tabValue: number }> = ({ tabValue }) => {
    // Define the inverse RdYlBu color palette for temperature
    const temperatureColors = [
      "#4575b4", "#91bfdb", "#e0f3f8", "#ffffbf", "#fee090", "#fc8d59", "#d73027"];
  
    // Define the BrBG color palette for precipitation
    const precipitationColors = 
      ['#8c510a','#d8b365','#f6e8c3','#f5f5f5','#c7eae5','#5ab4ac','#01665e'];

    const HDDColors =
      ['#eff3ff','#c6dbef','#9ecae1','#6baed6','#4292c6','#2171b5','#084594'];

    const CDDColors =
      ['#feedde','#fdd0a2','#fdae6b','#fd8d3c','#f16913','#d94801','#8c2d04'];

      interface LegendData {
        colors: string[];
        labels: [string, string];
      }
    
      const legends: Record<number, LegendData> = {
        0: {
          colors: temperatureColors,
          labels: ["Colder than normal", "Warmer than normal"],
        },
        1: {
          colors: precipitationColors,
          labels: ["Drier than normal", "Wetter than normal"],
        },
        2: {
          colors: HDDColors,
          labels: ["Lower HDD", "Higher HDD"],
        },
        3: {
          colors: CDDColors,
          labels: ["Lower CDD", "Higher CDD"],
        },
      };
    
    const legend = legends[tabValue];
  
    return (
      <div
        style={{
          position: "absolute",
          bottom: "40px",
          right: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: "10px",
          borderRadius: "5px",
          zIndex: 1,
          width: "300px", // Adjust the width to avoid text wrapping
        }}
      >
        <div>
          {/* Legend Colors */}
          <div style={{ display: "flex", height: "20px", marginBottom: "5px" }}>
            {legend.colors.map((color, index) => (
              <div
                key={index}
                style={{
                  flex: 1,
                  backgroundColor: color,
                }}
              />
            ))}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ color: "black", fontSize: "12px" }}>{legend.labels[0]}</span>
            <span style={{ color: "black", fontSize: "12px" }}>{legend.labels[1]}</span>
          </div>
        </div>
      </div>
    );
  };
  

  useEffect(() => {
    
    console.log("default useEffect: Starting forecast dates fetch");
    fetchForecastDates();

    if (!mapContainer.current) return;


    map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: {
          version: 8,
          sources: {
            'osm-tiles': {
              type: 'raster',
              tiles: ['https://api.mapbox.com/styles/v1/mapbox/navigation-night-v1/tiles/{z}/{x}/{y}?access_token='+mapboxgl.accessToken],
              tileSize: 256,
            }
          },
          layers: [{
            id: 'osm-tiles',
            type: 'raster',
            source: 'osm-tiles',
            //minzoom: 0,
            //maxzoom: 19,
          }]
        },
        center: [-74.5, 40],
        zoom: 3,
        minZoom: 3, 
        maxZoom: 10
      });


      popup.current = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });
  
      map.current.on('load', () => {
        console.log('default useEffect: Map has loaded successfully');
        updateMapStyle(tabValue);
        if (map.current) {
          map.current.on('mousemove', handleMapHover);
          map.current.on('mouseleave', handleMapLeave);
        }
      });
      
      map.current.on('mousemove', handleMapHover);
      map.current.on('mouseleave', handleMapLeave);

    map.current.addControl(new mapboxgl.NavigationControl(), 'top-left');

    return () => {
      if (map.current) map.current.remove();
    };
  }, []);

  // update layer names when layerMapping changes
  useEffect(() => {
    console.log(`layerNameMapping useEffect: Updating layer names with new mapping: ${layerNameMapping} and map.current condition:`, (!map.current || !map.current.loaded()));
    if (!map.current) return;

    // Get current layers and update their names
    const currentLayers = layers.map(layer => ({
      ...layer,
      name: layerNameMapping[layer.id] || layer.id
    }));

    console.log('layerNameMapping useEffect: Updating layers with new mapping names and setting state:', currentLayers.map(layer => layer.name).join(', '));
    setLayers(currentLayers);
  }, [layerNameMapping]);  

  const descriptions: Record<number, { title: string; content: string }> = {
    0: {
        title: "Temperature Forecast",
        content:
            "The map displays the forecast of temperature (in °C) for the indicated time period, expressed as the deviation from the usual (i.e. climatological) temperature for that region for that time of year. The climatological temperature is calculated from the period 2018 to 2023, so all the deviations should be interpreted relative to this baseline. Hover over different regions to view detailed anomaly information. ",
    },
    1: {
        title: "Precipitation Forecast",
        content:
            "The map displays the forecast of precipitation (in mm per day) for the indicated time period, expressed as the deviation from the usual (i.e. climatological) precipitation for that region for that time of year. The climatological precipitation is calculated from the period 2018 to 2023, so all the deviations should be interpreted relative to this baseline. Hover over different regions to view detailed anomaly information.",
    },
    2: {
      title: "Heating Degree Days Forecast",
      content: 
        "The map displays the forecast of heating degree days (HDD) for the indicated time period, expressed as the deviation from the usual (i.e. climatological) HDD for that region for that time of year. The climatological HDD is calculated from the period 2018 to 2023, so all the deviations should be interpreted relative to this baseline. Hover over different regions to view detailed anomaly information.",
    },
    3: {
      title: "Cooling Degree Days Forecast",
      content: 
        "The map displays the forecast of cooling degree days (CDD) for the indicated time period, expressed as the deviation from the usual (i.e. climatological) CDD for that region for that time of year. The climatological CDD is calculated from the period 2018 to 2023, so all the deviations should be interpreted relative to this baseline. Hover over different regions to view detailed anomaly information."
    },
    };



  const fetchForecastDates = async () => {
    try {
      console.log("fetchForecastDates: Fetching forecast dates");
      const response = await getPredictionDates();
      console.log("fetchForecastDates: Fetching forecast dates completed");
      const { forecast_dates, update_date } = response;
      // Create new layer mapping
      const newMapping = {
        'pr_0w': forecast_dates.weeks[0] + " to " + forecast_dates.weeks[1],
        'pr_1w': forecast_dates.weeks[1] + " to " + forecast_dates.weeks[2],
        'pr_2w': forecast_dates.weeks[2] + " to " + forecast_dates.weeks[3],
        'pr_3w': forecast_dates.weeks[3] + " to " + forecast_dates.weeks[4],
        'pr_4w': forecast_dates.weeks[4] + " to " + forecast_dates.weeks[5],
        'pr_1m': forecast_dates.months[0],
        'pr_2m': forecast_dates.months[1],
        'pr_3m': forecast_dates.months[2],
        'pr_1s': forecast_dates.season[0],
        't2m_0w': forecast_dates.weeks[0] + " to " + forecast_dates.weeks[1],
        't2m_1w': forecast_dates.weeks[1] + " to " + forecast_dates.weeks[2],
        't2m_2w': forecast_dates.weeks[2] + " to " + forecast_dates.weeks[3],
        't2m_3w': forecast_dates.weeks[3] + " to " + forecast_dates.weeks[4],
        't2m_4w': forecast_dates.weeks[4] + " to " + forecast_dates.weeks[5],
        't2m_1m': forecast_dates.months[0],
        't2m_2m': forecast_dates.months[1],
        't2m_3m': forecast_dates.months[2],
        't2m_1s': forecast_dates.season[0],
        'hdd_0w': forecast_dates.weeks[0] + " to " + forecast_dates.weeks[1],
        'hdd_1w': forecast_dates.weeks[1] + " to " + forecast_dates.weeks[2],
        'hdd_2w': forecast_dates.weeks[2] + " to " + forecast_dates.weeks[3],
        'hdd_3w': forecast_dates.weeks[3] + " to " + forecast_dates.weeks[4],
        'hdd_4w': forecast_dates.weeks[4] + " to " + forecast_dates.weeks[5],
        'hdd_1m': forecast_dates.months[0],
        'hdd_2m': forecast_dates.months[1],
        'hdd_3m': forecast_dates.months[2],
        'hdd_1s': forecast_dates.season[0],
        'cdd_0w': forecast_dates.weeks[0] + " to " + forecast_dates.weeks[1],
        'cdd_1w': forecast_dates.weeks[1] + " to " + forecast_dates.weeks[2],
        'cdd_2w': forecast_dates.weeks[2] + " to " + forecast_dates.weeks[3],
        'cdd_3w': forecast_dates.weeks[3] + " to " + forecast_dates.weeks[4],
        'cdd_4w': forecast_dates.weeks[4] + " to " + forecast_dates.weeks[5],
        'cdd_1m': forecast_dates.months[0],
        'cdd_2m': forecast_dates.months[1],
        'cdd_3m': forecast_dates.months[2],
        'cdd_1s': forecast_dates.season[0],
      };

      console.log("fetchForecastDates: Setting forecast dates to state variables");
      setLayerNameMapping(newMapping);
      setUpdateDate(update_date);
      console.log("fetchForecastDates: Updated forecast dates to state variables");
    
    } catch(error) {
      console.error('Error fetching forecast dates:', error);
    }
  }

  const updateMapStyle = (tabIndex: number) => {
    console.log("updateMapStyle: Updating map for tabValue:", tabIndex);
    setTabValue(tabIndex);
    console.log("updateMapStyle: map.current condition:", (!map.current));
    if (!map.current) return;

    const styleId = tabIndex === 0 ? TEMPERATURE_STYLE_ID : 
                    tabIndex === 1 ? PRECIPITATION_STYLE_ID :
                    tabIndex === 2 ? HDD_STYLE_ID :
                    tabIndex === 3 ? CDD_STYLE_ID : '';
    const styleUrl = `mapbox://styles/${USERNAME}/${styleId}`;

    console.log(`Fetching style from: ${styleUrl}`);

    fetch(`https://api.mapbox.com/styles/v1/${USERNAME}/${styleId}?access_token=${mapboxgl.accessToken}`)
      .then(response => response.json())
      .then(customStyle => {
        console.log('updateMapStyle: Fetched custom style:', customStyle);

        // Remove previous custom layers and sources
        map.current!.getStyle().layers.forEach(layer => {
          if (!layer.id.includes('label') && layer.id !== 'osm-tiles') {  // Keep label layers
            map.current!.removeLayer(layer.id);
          }
        });
        Object.keys(map.current!.getStyle().sources).forEach(sourceId => {
          if (sourceId !== 'osm-tiles') {
            map.current!.removeSource(sourceId);
          }
        });

        Object.entries(customStyle.sources).forEach(([id, source]: [string, any]) => {
          if (!map.current!.getSource(id)) {
            map.current!.addSource(id, source);
          }
        });

        const newLayers: Layer[] = [];
        console.log('updateMapStyle: Adding layers with names:', layerNameMapping);
        customStyle.layers.forEach((layer: any) => {
            if (!layer.id.includes('label')) {
            map.current!.addLayer(layer);
            newLayers.push({
              id: layer.id,
              name: layerNameMapping[layer.id]
            });
          }
        });

        newLayers.reverse();

        console.log('updateMapStyle: Setting layers with names to state var when fetch is complete:', newLayers.map(layer => layer.name).join(', '));
        setLayers(newLayers);
        console.log('updateMapStyle: Layers updated in state var');

        if (newLayers.length > 0) {
          const topLayerId = newLayers[0].id;
          setActiveLayer(topLayerId);
          
          newLayers.forEach((layer) => {
            map.current!.setLayoutProperty(
              layer.id,
              'visibility',
              layer.id === topLayerId ? 'visible' : 'none'
            );
          });
        } else {
          setActiveLayer(null);
        }

        console.log('updateMapStyle: Updated map style');
      })
      .catch(error => {
        console.error('Error fetching style:', error);
      });
  };

  const handleLayerChange = (layerId: string) => {
    setActiveLayer(layerId);
    
    layers.forEach(layer => {
      map.current!.setLayoutProperty(
        layer.id,
        'visibility',
        layer.id === layerId ? 'visible' : 'none'
      );
    });
  };

  const handleMapHover = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    //console.log("Current tabValue:", tabValueRef.current);
    
    if (!map.current || !popup.current) {
      console.error('Map or popup is not initialized');
      return;
    }

    let features;
    try {
      features = map.current.queryRenderedFeatures(e.point);
    } catch (error) {
      console.error('Error querying rendered features:', error);
      return;
    }

    if (features.length > 0) {
      const hoveredFeature = features[0];
      const properties = hoveredFeature.properties;

      //console.log(properties);

      if (properties) {
        let hoverText = '';
        const country = properties.country || 'N/A';
        const state = properties.state || 'N/A';
        const county = properties.region || 'N/A';

        let anom_value = -10000;
        let actual_value = -10000;
        const layerId = hoveredFeature.layer.id;
        const [variable, timeframe] = layerId.split('_');

        if (timeframe) {
          anom_value = properties[`anom_${layerId}`];
          
          // For HDD and CDD, also get the actual forecast value
          if (variable === 'hdd' || variable === 'cdd') {
            actual_value = properties[`forecast_${variable}_mean_${timeframe}`];
          }
        }

        if (anom_value != -10000) {
          if (tabValueRef.current === 2 || tabValueRef.current === 3) {

            const abs_anom = Math.abs(anom_value).toFixed(0); // Absolute value for comparison and use
    
            let anomalyDescription;
            if (Number(abs_anom) === 0) { // Compare abs_anom to 0
                anomalyDescription = "None";
            } else if (anom_value < 0) { // Original anom_value determines direction
                anomalyDescription = `${abs_anom} fewer degree days`;
            } else { // anom_value > 0
                anomalyDescription = `${abs_anom} more degree days`;
            }
              
              hoverText = `${actual_value.toFixed(0)} Degree Days\nAnomaly: ${anomalyDescription}\nCountry: ${country}\nState: ${state}\nCounty: ${county}`;

            //hoverText = `Anomaly: ${anom_value.toFixed(3)}\nActual: ${actual_value.toFixed(0)} Degree Days\nCountry: ${country}\nState: ${state}\nCounty: ${county}`;
          } else {
          hoverText = `Anomaly: ${anom_value.toFixed(3)}\nCountry: ${country}\nState: ${state}\nCounty: ${county}`; }
        } else {
          hoverText = 'No data available';
        }

        popup.current
          .setLngLat(e.lngLat)
          .setHTML(hoverText.replace(/\n/g, '<br>'))
          .addTo(map.current);

      } else {
        popup.current.remove();
      }
    } else {
      popup.current.remove();
    }
  };

  const handleMapLeave = () => {
    if (popup.current) {
      popup.current.remove();
    }
  };

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
      <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />

        <Typography
        variant="h6"
        style={{
            position: 'absolute',
            top: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 3,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '10px 20px',
            borderRadius: '8px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            fontFamily: 'Avenir, sans-serif',
        }}
    >
        SURA<span style={{ fontSize: '70%' }}><sup>TM</sup></span> Forecasts
    </Typography>

      <div style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '4px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        zIndex: 2,
      }}>
        <div style={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
        }}>
          {/* Temperature/Precipitation selector */}
          <FormControl
            size="small"
            sx={{
              minWidth: '150px',
            }}
          >
            <Select
              value={tabValue}
              onChange={(e) => {
                const newTabValue = e.target.value as number;
                //setTabValue(newTabValue);
                setTabValue(Number(e.target.value));
                updateMapStyle(newTabValue);
              }}
              sx={{ 
                fontFamily: 'Avenir, sans-serif',
                height: '35px',
                '.MuiSelect-select': {
                  padding: '5px 14px'
                }
              }}
            >
              <MenuItem value={0}>Temperature</MenuItem>
              <MenuItem value={1}>Precipitation</MenuItem>
              <MenuItem value={2}>Heating Degree Days</MenuItem>
              <MenuItem value={3}>Cooling Degree Days</MenuItem>
            </Select>
          </FormControl>

          <LayerControl 
            layers={layers} 
            activeLayer={activeLayer} 
            onLayerChange={handleLayerChange} 
          />
        </div>
      </div>

      <Legend tabValue={tabValue} />

        <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: isDescriptionVisible ? '0' : '-250px',
                    transform: 'translateY(-50%)',
                    width: '250px',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '0 5px 5px 0',
                    boxShadow: '2px 0 5px rgba(0, 0, 0, 0.2)',
                    zIndex: 3,
                    transition: 'left 0.3s ease-in-out',
                }}

            >
                <Typography variant="h6" gutterBottom>
                    {descriptions[tabValue].title}
                </Typography>
                <Typography variant="body2">{descriptions[tabValue].content}<br/><br/><b>Updated: {updateDate}</b></Typography>
            </div>

            {/* Toggle button for description */}
            <Button
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: isDescriptionVisible ? '250px' : '10px',
                    transform: 'translateY(-20%)',
                    zIndex: 4,
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                    minWidth: '40px',
                    minHeight: '40px',
                }}
                onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
            >
                ?
            </Button>

      <button
        style={{
          position: 'absolute',
          bottom: '40px',
          left: '10px',
          backgroundColor: 'white',
          zIndex: 2,
          padding: '10px',
          borderRadius: '4px',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
          color: 'black',
        }}
        
        onClick={() => navigate('/home')}
      >
        Home
      </button>
    </div>
  );
};

export default FullscreenSura;
